app.directive('paging',function($rootScope) {
	return {
		restrict: 'AE', 
		replace: true, 
		scope: {
			page: '=?',
			pageSize: '=?',
			total: '=?',
			limit: '=?',
			callback: '=?'
		},
		templateUrl: $rootScope.setup.template + 'partials/paging.html',
		link: function(scope,element,attrs) {

			// # Define calculation

			function build() {
				
				scope.pages = Math.ceil(scope.total / scope.pageSize) || 1;

			}

			// # Watch changes

			scope.$watchCollection('[page,pageSize,total,limit]', function () {
				build();
			});
		}
	}
});

app.directive('icon',function($timeout) {
	return {
		restrict: 'AE', 
		replace: false, 
		scope: {
			ngModel: '=?',
			weather: '=?',
			code: '=?',
			class: '@?'
		},
		link: function(scope,element,attrs) {

            // # Define wrapper

            var data = '<span class="icon-wrapper"></span>';

            // # Inject html

            element.html(data);

			// # Convert code to icon

			scope.seticon = function() {
				if(scope.weather && scope.code) {
					if(scope.code == '01d') {
						scope.class = 'wi wi-day-sunny';
					} else if(scope.code == '02d') {
						scope.class = 'wi wi-day-cloudy';
					} else if(scope.code == '03d') {
						scope.class = 'wi wi-cloud';
					} else if(scope.code == '04d') {
						scope.class = 'wi wi-cloudy';
					} else if(scope.code == '09d') {
						scope.class = 'wi wi-rain';
					} else if(scope.code == '10d') {
						scope.class = 'wi wi-day-rain';
					} else if(scope.code == '11d') {
						scope.class = 'wi wi-day-thunderstorm';
					} else if(scope.code == '13d') {
						scope.class = 'wi wi-day-snow-wind';
					} else if(scope.code == '50d') {
						scope.class = 'wi wi-dust';
					} else if(scope.code == '01n') {
						scope.class = 'wi wi-night-clear';
					} else if(scope.code == '02n') {
						scope.class = 'wi wi-night-alt-cloudy';
					} else if(scope.code == '03n') {
						scope.class = 'wi wi-cloud';
					} else if(scope.code == '04n') {
						scope.class = 'wi wi-cloudy';
					} else if(scope.code == '09n') {
						scope.class = 'wi wi-rain';
					} else if(scope.code == '10n') {
						scope.class = 'wi wi-night-alt-rain';
					} else if(scope.code == '11n') {
						scope.class = 'wi wi-night-thunderstorm';
					} else if(scope.code == '13n') {
						scope.class = 'wi wi-night-alt-snow';
					} else if(scope.code == '50n') {
						scope.class = 'wi wi-dust';
					} element.html('<i class="' + (scope.class || '') + '"></i>'); 
				}
			}; scope.seticon();

            // # Watch state

            scope.$watch('code',function(value) { 
				scope.seticon(); $timeout(function() {
					if(value) { 
						element.find('.icon-wrapper *').remove(); element.find('.icon-wrapper').html('<i class="' + (scope.class || '') + ' ' + (scope.ngModel || '') + '"></i>'); 
					} else { 
						element.find('.icon-wrapper *').remove(); 
					}
				});
			});
		}
	}
});