app.run(function($rootScope,$storage,$appService,$translate,$interval,$location,$route,$http,$filter,$storage,$routeParams,cfpLoadingBar,$sce,$q) {

	// # Define data

	$rootScope.token  	= $storage.get('token');
	$rootScope.setup  	= $storage.get('setup');
	$rootScope.routes  	= $storage.get('routes');
	$rootScope.user  	= $storage.get('user');
	$rootScope.i18n  	= $storage.get('i18n');
	$rootScope.weather 	= $storage.get('weather');
	$rootScope.now 		= moment();

	// # Check existing token

	if($rootScope.token) { $appService.set_token(); }

	// # Loader events

	$rootScope.$on('cfpLoadingBar:started',function() {
		$rootScope.load = true;
	});

	$rootScope.$on('cfpLoadingBar:completed',function() {
		$rootScope.load = false;
	});

	// # Start application

	$appService.do_setup(true).then(function() {
		$appService.do_data(true).then(function() {

			// # Register services

			$appService.do_route();
			$appService.set_session();

			// # Fetch weather

			$rootScope.get_weather();

		});
	});

	$rootScope.get_geolocation = function() {
		try {
			var defer = $q.defer(); if($rootScope.geolocation) {
				defer.resolve($rootScope.geolocation);
			} else { 
				if(navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function(position) { 
						$rootScope.$apply(function() { $rootScope.geolocation = $storage.set('geolocation',{latitude:position.coords.latitude,longitude:position.coords.longitude}); defer.resolve($rootScope.geolocation); });
					},function() { defer.reject(); });
				} else { defer.reject(); }
			} return defer.promise;
		} catch(e) { if($rootScope.setup.mode == 'sandbox') { console.error(e); } }
	}; 

	$rootScope.get_weather = function() {
		try { 
			var defer = $q.defer(); if($rootScope.weather && $rootScope.weather.updated && moment().diff($rootScope.weather.updated,'hours') < 1) {
				defer.resolve($rootScope.weather);
			} else { 
				$rootScope.get_geolocation().then(function(position) {
					$http.jsonp($sce.trustAsResourceUrl('https://api.openweathermap.org/data/2.5/forecast?appid=' + $rootScope.setup.openweathermap + '&lang=' + $rootScope.setup.language + '&units=metric&lat=' + position.latitude + '&lon=' + position.longitude)).then(function(response) {
						
						// # Predefine

						var wlist = [];

						// # Build list

						var weatherList_s1 = $filter('filter')(response.data.list,{dt:moment().utc().add(0,'days').set('hour',15).set('minute',0).set('second',0).format('X')})[0];
						if(weatherList_s1) { weatherList_s1.main.temp_smallest = $filter('filter')(response.data.list,{dt:moment().utc().add(1,'days').set('hour',3).set('minute',0).set('second',0).format('X')})[0].main.temp_min; wlist.push(weatherList_s1); }

						var weatherList_s2 = $filter('filter')(response.data.list,{dt:moment().utc().add(1,'days').set('hour',15).set('minute',0).set('second',0).format('X')})[0];
						if(weatherList_s2) { weatherList_s2.main.temp_smallest = $filter('filter')(response.data.list,{dt:moment().utc().add(2,'days').set('hour',3).set('minute',0).set('second',0).format('X')})[0].main.temp_min; wlist.push(weatherList_s2); }

						var weatherList_s3 = $filter('filter')(response.data.list,{dt:moment().utc().add(2,'days').set('hour',15).set('minute',0).set('second',0).format('X')})[0];
						if(weatherList_s3) { weatherList_s3.main.temp_smallest = $filter('filter')(response.data.list,{dt:moment().utc().add(3,'days').set('hour',3).set('minute',0).set('second',0).format('X')})[0].main.temp_min; wlist.push(weatherList_s3); }

						var weatherList_s4 = $filter('filter')(response.data.list,{dt:moment().utc().add(3,'days').set('hour',15).set('minute',0).set('second',0).format('X')})[0];
						if(weatherList_s4) { weatherList_s4.main.temp_smallest = $filter('filter')(response.data.list,{dt:moment().utc().add(4,'days').set('hour',3).set('minute',0).set('second',0).format('X')})[0].main.temp_min; wlist.push(weatherList_s4); }

						var weatherList_s5 = $filter('filter')(response.data.list,{dt:moment().utc().add(4,'days').set('hour',15).set('minute',0).set('second',0).format('X')});
						if(weatherList_s5) { weatherList_s5 = weatherList_s5[0]; weatherList_s5.main.temp_smallest = weatherList_s5.main.temp_min; wlist.push(weatherList_s5); }

						// # Store data

						$rootScope.weather = $storage.set('weather',{
							updated: moment(),
							forecast: wlist,
							today: weatherList_s1,
							location: response.data.city,
							selected: weatherList_s1
						}); defer.resolve($rootScope.weather);
					}).catch(function(response) {
						defer.reject();
					});
				},function() { defer.reject(); });
			} return defer.promise;
		} catch(e) { if($rootScope.setup.mode == 'sandbox') { console.error(e); } }
	};

	// # Application events

	$rootScope.$on('$routeChangeStart',function(event,nextRoute,currentRoute) {

		// # Set route data

		$rootScope.route  		= nextRoute ? nextRoute.data : currentRoute.data;
		$rootScope.route_last 	= currentRoute ? currentRoute.data : {};

		// # Set route params data

		if($rootScope.route) { $rootScope.route.params = angular.extend({},$routeParams,$location.search()); }

		// # Check protected

		if($rootScope.route && $rootScope.user && $rootScope.user.type_key != 'admin' && ($rootScope.route.key == 'user' || $rootScope.route.key == 'users')) {
			$location.path('/403');
		}

		// # Check redirect

		if($rootScope.route && $rootScope.route.redirect && $rootScope.user) {
			$location.path($rootScope.route.redirect);
		}

	});

	$rootScope.$on('$viewContentLoaded',function() {

		// # Prevent open mobile navigation

		$('body').click();

	});

	// # Application settings

	$rootScope.tinymceOptions = {
		baseURL: '/app/dist/js/',
		plugins: 'link image code',
		menubar: false,
		toolbar: 'undo redo | bold italic | alignleft aligncenter alignright',
		height: 220,
		language: 'de'
	};

});