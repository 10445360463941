app.filter('truncate',function () {
    return function(input,words) {
        if (isNaN(words)) return input;
        if (words <= 0) return '';
        if (input) {
            var inputWords = input.toString().split('');
            if (inputWords.length > words) {
                input = inputWords.slice(0, words).join('') + '…';
            }
        }
        return input;
    };
});

app.filter('range',function() {
    return function(n) {
        var res = []; for (var i = 1; i < n; i++) {res.push(i);} return res;
    }
});

app.filter('replace',function() {
    return function(input,from,to) {
        input = input || ''; from = from || ''; to = to || ''; return input.replace(from,to);
    };
});

app.filter('bytes', function() {
    return function(bytes, precision) {
        if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
        if (typeof precision === 'undefined') precision = 1;
        var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
            number = Math.floor(Math.log(bytes) / Math.log(1024));
        return ((bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number]).toString().replace('.',',');
    }
});