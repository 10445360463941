app.controller('appCtrl',function($scope,$rootScope,$location,$translate,$route,$routeParams,$storage,$interval,$filter,$http,$ngConfirm,$appService) {

	// # Predefine data

	$scope.data = {};
	$scope.form = {newpassword:{}};
	$scope.logindata = {username:$storage.getLocal('username'),password:$storage.getLocal('password'),remember:$storage.getLocal('remember')};

	// # Login

	$scope.login = function() {

		// # Reset errors and set loading state

		$scope.form.login.load = true;
		$scope.form.login.error = false;

		// # Api call

		$http.post('/api/authenticate',{
			username: $scope.logindata.username,
			password: $scope.logindata.password
		}).then(function(response) {

			// # Reset loading state
			
			$scope.form.login.load = false;
			
			// # Store token

			$rootScope.token = $storage.set('token',response.data); $appService.set_token();

			// # Fetch user data

			$http.get('/api/user/' + response.data.user).then(function(response) {

				// # Set data

				$rootScope.user = $storage.set('user',response.data);

				// # Register session interval

				$appService.set_session();

				// # Store remember

				if($scope.logindata.remember) {
					$storage.setLocal('username',$scope.logindata.username);
					$storage.setLocal('password',$scope.logindata.password);
					$storage.setLocal('remember',$scope.logindata.remember);
					
				} else {
					$scope.logindata = {
						username: $storage.rmLocal('username'),
						password: $storage.rmLocal('password'),
						remember: $storage.rmLocal('remember')
					};
				}

				// # Redirect

				$location.reload();

			});

		},function(response) {

			// # Set error and loading state

			$scope.form.login.load = false;
			$scope.form.login.error = true;

		});

	};

	// # Logout

	$scope.logout = function() {
		$ngConfirm({
			theme: 'supervan',
			useBootstrap: false,
			title: $translate.instant('i18n_login_logout_confirm_title'),
			content: $translate.instant('i18n_login_logout_confirm_description'),
			scope: $scope,
			buttons: {
				confirm: {
					text: $translate.instant('i18n_login_logout_confirm_do'),
					btnClass: 'uk-button uk-button-primary',
					action: function(scope,button) {
						scope.$apply(function() {

							// # Update data

							$rootScope.user = $storage.rm('user');
							$rootScope.token = $storage.rm('token'); $appService.remove_token();
							$appService.remove_session();

							// # Redirect

							$location.path('/');

						});
					}
				},
				abort: {
					text: $translate.instant('i18n_login_logout_confirm_abort'),
					btnClass: 'uk-button uk-button-default'
				}
			}
		});
	};

	$scope.newpassword_submit = function(data) {

		// # Set loading state

		$scope.form.newpassword.load = true;

		// # Api call

		$http.put('/api/user/' + $rootScope.user.uid,{
			password: 			data.password,
			password_confirm: 	data.password_confirm
		}).then(function(response) {

			// # Reset loading state
			
			$scope.form.newpassword.load = false;
			
			// # Notify

			$appService.do_notify('success');

			// # Reset values and hide modal

			data.password = null; data.password_confirm = null;
			UIkit.modal('#modal-newpassword').hide();

		},function(response) {

			// # Set error and loading state

			$scope.form.newpassword.load = false;

			// # Evaluate errors

			if(response.data.errors) {
				angular.forEach(response.data.errors,function(error) {
					$scope.form.newpassword[error.column].$setValidity('invalid',false);
				});
			}
		});
	};
});