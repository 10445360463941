app.config(function($controllerProvider,$locationProvider,$compileProvider,$filterProvider,$httpProvider,$routeProvider,$sceProvider,$qProvider,$translateProvider,$provide,momentPickerProvider) {

	// # Set references

	$routeProviderReference = $routeProvider;
	$qProviderReference = $qProvider;
	$httpProviderReference = $httpProvider;
	$translateProviderReference = $translateProvider;

	// # Disable cache

	if(!$httpProviderReference.defaults.headers.get) { $httpProviderReference.defaults.headers.get = {}; }
	$httpProviderReference.defaults.withCredentials = true;
    $httpProviderReference.defaults.cache = true;

	// # Set HTML5 mode

	$locationProvider.html5Mode(true);

	// # Prevent unhandled rejection error message from angular 1.5.9

	$qProviderReference.errorOnUnhandledRejections(false);

	// # Disable sce restricted urls

	$sceProvider.enabled(false);
	
	// # Define object/date-string conversion

	var dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/; var dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/; function convertDateStringsToDates(object) {
		var result = object; if(object != null) {
			result = angular.copy(object); for(var key in result) {
				var property = result[key]; if (typeof property === 'object') { result[key] = convertDateStringsToDates(property); } else if (typeof property === 'string' && (dateRegex.test(property) || dateTimeRegex.test(property))) { result[key] = moment(property); }
			}
		} return result;
	}

	// # Register transformer
	
	$httpProvider.defaults.transformResponse = function(data) {
		try { var object; if(typeof data === 'object') { object = data; } else { object = JSON.parse(data); } return convertDateStringsToDates(object); } catch(e) { return data; }
	};
	
	// # Register provider

	app.register = {
		controller: $controllerProvider.register,
		directive: 	$compileProvider.directive,
		filter: 	$filterProvider.register,
		factory: 	$provide.factory,
		service: 	$provide.service
	};

	// # Set translation escape strategy

	$translateProvider.useSanitizeValueStrategy('escape');

	// # Set datepicker options

	momentPickerProvider.options({
		locale: 'de',
		today: true
	});

});