app.factory('$check',function() {
	return {
		json: function(obj) { try { JSON.parse(obj); } catch(e) { return false; } return true; }, 
		js: function(path) { var isloaded = false; angular.forEach(angular.element(document).find('script'),function(elm) { if(angular.element(elm).attr('src') == path) { isloaded = true; } }); return isloaded; }
	}
});

app.factory('$storage',['$window','$check',function($window,$check,$rootScope) {
	return {
		set: function(idx,val) { if(angular.isObject(val)) { $window.sessionStorage && $window.sessionStorage.setItem(idx,JSON.stringify(val)); return val; } else { $window.sessionStorage && $window.sessionStorage.setItem(idx,val); return val; }},
		setLocal: function(idx,val) { if(angular.isObject(val)) { $window.localStorage && $window.localStorage.setItem(idx,JSON.stringify(val)); return val; } else { $window.localStorage && $window.localStorage.setItem(idx,val); return val; }},
		get: function(idx) { if($check.json($window.sessionStorage.getItem(idx))) { var $object = JSON.parse($window.sessionStorage.getItem(idx)); return ($object === 'undefined') ? '' : $window.sessionStorage && $object; } else { return ($window.sessionStorage.getItem(idx) === 'undefined') ? '' : $window.sessionStorage && $window.sessionStorage.getItem(idx); }},
		getLocal: function(idx) { if($check.json($window.localStorage.getItem(idx))) { var $object = JSON.parse($window.localStorage.getItem(idx)); return ($object === 'undefined') ? '' : $window.localStorage && $object; } else { return ($window.localStorage.getItem(idx) === 'undefined') ? '' : $window.localStorage && $window.localStorage.getItem(idx); }},
		rm: function(idx) { $window.sessionStorage && $window.sessionStorage.removeItem(idx); return ''; },
		rmLocal: function(idx) { $window.localStorage && $window.localStorage.removeItem(idx); return ''; }
	};
}]);

app.factory('$appService',function($rootScope,$location,$route,$window,$interval,$storage,$translate,$http,amMoment,$q) {

	// # Instance

	var instance = {};

	// # Build application setup

	instance.do_setup = function(force) {

		// # Define async object

		var deferred = $q.defer(),
			promises = [];

		// # DATA - setup

		if(!$rootScope.setup || force) {
			promises.push($http.get('app/config.json').then(function(response) {
					
				// # Store data

				$rootScope.setup = response.status == 200 ? $storage.set('setup',response.data) : {};

			}));
		}

		// # DATA - routes

		if(!$rootScope.routes || force) {
			promises.push($http.get('app/routes.json').then(function(response) {
					
				// # Store data

				$rootScope.routes = response.status == 200 ? $storage.set('routes',response.data) : {};

			}));
		}

		// # Resolve
		
		if(promises) {			
			$q.all(promises).then(function() {
				deferred.resolve();
			},function() {
				deferred.resolve();
			});
		} else {
			deferred.resolve();
		}

		// # Return promise

		return deferred.promise;

	};

	// # Build application data

	instance.do_data = function(force) {

		// # Define async object

		var deferred = $q.defer(),
			promises = [];

		// # DATA - setup

		if(!$rootScope.i18n || force) {
			promises.push($http.get('app/dist/i18n/' + $rootScope.setup.language + '.json').then(function(response) {
					
				// # Store data

				$rootScope.i18n = response.status == 200 ? $storage.set('i18n',response.data) : {};

			}));
		}

		// # Resolve
		
		if(promises) {			
			$q.all(promises).then(function() {

				// # Locale data

				amMoment.changeLocale($rootScope.setup.language);

				// # Load translation

				$translateProviderReference.translations($rootScope.setup.language,$rootScope.i18n);
				$translateProviderReference.preferredLanguage($rootScope.setup.language);
				$translate.use($rootScope.setup.language);

				// # Resolve

				deferred.resolve();

			},function() {
				deferred.resolve();
			});
		} else {
			deferred.resolve();
		}

		// # Return promise

		return deferred.promise;

	};

	// # Build route data

	instance.do_route = function() {

		// # Iterate routes

		angular.forEach($rootScope.routes,function(route) { 
			$routeProviderReference.when(route.url, {
				cache: false, 
				templateUrl: $rootScope.setup.template + (route.location || '') + route.template,
				controller: route.controller,
				data: route,
				resolve: {
					load: function($q) { 

						// # Define async object
			
						var defer = $q.defer(),promises = [];

						// # Check controller file

						if(route.controller_file) {
							promises.push(loadJS([$rootScope.setup.template + (route.location || '') + route.controller_file]));
						}

						// # Resolve all promises
						
						$q.all(promises).then(function() {
							defer.resolve();
						},function() {
							defer.reject();
						}); 

						// # Return promise

						return defer.promise;

					}
				}
			});
		});

		// # Define fallback

		$routeProviderReference.otherwise({redirectTo:'/404'});

		// # Reload routes

		$route.reload();

	};

	// # Set token

	instance.set_token = function() {
		$http.defaults.headers.common.Authorization = 'Bearer ' + $rootScope.token.token;
	};

	// # Remove token

	instance.remove_token = function() {
		delete $http.defaults.headers.common.Authorization;
	};

	// # Set session

	instance.set_session = function() {
		if($rootScope.user) { $rootScope.session = $interval(function() { $http.put('/api/session/ping',{},{ignoreLoadingBar:true});},60000); }
	};

	// # Remove session

	instance.remove_session = function() {
		if($rootScope.session) { $interval.cancel($rootScope.session); }
	};

	// # Notification

	instance.do_notify = function(type,message,group,timeout) {
		
		// # Check group

		if(group) {
			UIkit.notification.closeAll(group);
		}

		// # Switch type

		if(type == 'success') {
			var content = '<span uk-icon="icon:check"></span> <strong>' + $translate.instant('i18n_general_notify_success_title') + '</strong><p>' + $translate.instant(message || 'i18n_general_notify_success_default') + '</p>';
		} else if(type == 'error') {
			var content = '<span uk-icon="icon:close"></span> <strong>' + $translate.instant('i18n_general_notify_danger_title') + '</strong><p>' + $translate.instant(message || 'i18n_general_notify_danger_default') + '</p>';
		} else if(type == 'warning') {
			var content = '<span uk-icon="icon:warning"></span> <strong>' + $translate.instant('i18n_general_notify_warning_title') + '</strong><p>' + $translate.instant(message || 'i18n_general_notify_warning_default') + '</p>';
		} else if(type == 'info') {
			var content = '<span uk-icon="icon:info"></span> <strong>' + $translate.instant('i18n_general_notify_info_title') + '</strong><p>' + $translate.instant(message || 'i18n_general_notify_info_default') + '</p>';
		} else if(type == 'load') {
			var content = '<div class="uk-flex uk-flex-middle"><div class="uk-margin-small-right"><div class="uk-inline-block" uk-spinner></div></div><div class="uk-flex-auto"><strong>Bitte warten...</strong><p>' + $translate.instant(message) + '</p></div></div>';
		} else {
			var content = '';
		}

		UIkit.notification({
			message: content,
			status: type,
			pos: 'top-right',
			group: group || null,
			timeout: timeout || 5000
		});

	};

	// # Build header filter

	instance.buildheader = function(input) {
		try {
			
			// # Define data

			var data = {
				filter: 		input.filter || null, 
				filter_raw: 	input.filter_raw || null,
				search: 		input.search || null, 
				search_raw: 	input.search_raw || null,
				limit: 			input.limit || null,
				lastid: 		input.lastid || null,
				order: 			input.order || null,
				order_raw: 		input.order_raw || null,
				reset: 			input.reset || false
			}; var origin = angular.copy(data); var headers = {'x-api-filter':null,'x-api-search':null,'x-api-order':null,'x-api-limit':null,'x-api-lastid':null};

			// # Build filter

			if(is.not.null(data.filter_raw)) {
				headers['x-api-filter'] = data.filter_raw; 
			} if(is.object(data.filter) && is.array(data.filter.fields)) {
				angular.forEach(data.filter.fields,function(filter,index) {
					if(filter.value != '!' && filter.value != undefined && (!filter.isdate || filter.isdate && filter.value != '')) {

						// # Check type

						if(!filter.type) {
							filter.type = '=';
						}

						// # Check value and compare first entry in subsets

						if(is.null(filter.value) && filter.subset && data.subsets && data.subsets[filter.subset] && data.subsets[filter.subset].results.length) {
							filter.value = data.subsets[filter.subset].results[0].id;
						}

						// # Catch null value

						if(is.null(headers['x-api-filter'])) { headers['x-api-filter'] = ''; }

						// # Build filter
						
						if(filter.instr) {
							if(filter.value != 'NaN') { headers['x-api-filter'] += is.empty(headers['x-api-filter']) ? filter.key + ':instr:' + filter.value : ';' + filter.key + ':instr:' + filter.value; }
						} else if(filter.inarr) {
							if(filter.value != 'NaN') { headers['x-api-filter'] += is.empty(headers['x-api-filter']) ? filter.key + ':inarr:' + filter.value : ';' + filter.key + ':inarr:' + filter.value; }
						} else if(filter['-inarr']) {
							if(filter.value != 'NaN') { headers['x-api-filter'] += is.empty(headers['x-api-filter']) ? filter.key + ':-inarr:' + filter.value : ';' + filter.key + ':-inarr:' + filter.value; }
						} else if(filter.isdate) {
							if(filter.value != 'NaN') { headers['x-api-filter'] += is.empty(headers['x-api-filter']) ? filter.key + ':' + filter.type + ':' + moment(filter.value,(filter.dateinformat || 'DD.MM.YYYY')).format(filter.dateformat || 'YYYY-MM-DD') : ';' + filter.key + ':' + filter.type + ':' + moment(filter.value,(filter.dateinformat || 'DD.MM.YYYY')).format(filter.dateformat || 'YYYY-MM-DD'); }
						} else {
							if(filter.value != 'NaN') { headers['x-api-filter'] += is.empty(headers['x-api-filter']) ? filter.key + ':' + filter.type + ':' + filter.value : ';' + filter.key + ':' + filter.type + ':' + filter.value; }
						}
					}
				});
			}

			// # Build search

			if(is.truthy(data.search_raw)) {
				headers['x-api-search'] = data.search_raw;
			} else if(is.object(data.search) && is.not.null(data.search.value) && is.not.empty(data.search.value)) { 
				headers['x-api-search'] = data.search.field + ':' + data.search.value; 
			}

			// # Build order

			if(is.truthy(data.order_raw)) {
				headers['x-api-order'] = data.order_raw;
			} else if(is.object(data.order) && is.not.null(data.order.sort) && is.not.empty(data.order.sort)) { 
				headers['x-api-order'] += data.order.field + ':' + data.order.sort; 
			}
			
			// # Build limit 

			if(is.truthy(data.limit)) { 
				headers['x-api-limit'] = parseInt(data.limit) * 2; 
			}

			// # Build lastid 

			if(is.truthy(data.lastid)) { 
				headers['x-api-lastid'] = parseInt(data.lastid); 
			}

			// # Check empty headers

			if(is.truthy(data.reset) || is.not.truthy(headers['x-api-filter'])) { delete headers['x-api-filter']; }
			if(is.truthy(data.reset) || is.not.truthy(headers['x-api-search'])) { delete headers['x-api-search']; }
			if(is.truthy(data.reset) || is.not.truthy(headers['x-api-order'])) { delete headers['x-api-order']; }
			if(is.truthy(data.reset) || is.not.truthy(headers['x-api-limit'])) { delete headers['x-api-limit']; }
			if(is.truthy(data.reset) || is.not.truthy(headers['x-api-lastid'])) { delete headers['x-api-lastid']; }

			// # Return headers

			return headers;

		} catch(e) { console.log(e); }
	};

	// # Request 

	instance.request = function(input) {
		try {

			// # Define async object and loader

			var deferred = $q.defer(); var data = {
				'url': is.undefined(input.url) ? null : input.url,
				'type': is.undefined(input.type) ? 'get' : input.type.toString().toLowerCase(),
				'loader': is.undefined(input.loader) ? false : (is.truthy(input.loader) ? false : true),
				'cache': is.undefined(input.cache) ? false : input.cache,
				'headers': (is.undefined(input.headers) || is.not.object(input.headers)) ? {} : input.headers,
				'data': (is.undefined(input.data) || is.not.object(input.data)) ? {} : input.data
			}; data.options = angular.extend({ignoreLoadingBar:data.loader,cache:data.cache},{headers:data.headers});

			// # Array buffer option

			if(input && input.file) {
				data.options['responseType'] = 'arraybuffer';
			}

			// # Api call

			if(data.type == 'get') {
				$http.get($rootScope.setup.api + data.url,data.options).then(function(response) {
					deferred.resolve(response);
				},function(response) {
					if(response.status == 401) {
						instance.logout();
					} else {
						deferred.reject(response);
					}
				});
			} else if(data.type == 'post') {
				$http.post($rootScope.setup.api + data.url,data.data,data.options).then(function(response) {
					deferred.resolve(response);
				},function(response) {
					if(response.status == 401) {
						instance.logout();
					} else {
						deferred.reject(response);
					}
				});
			} else if(data.type == 'put') {
				$http.put($rootScope.setup.api + data.url,data.data,data.options).then(function(response) {
					deferred.resolve(response);
				},function(response) {
					if(response.status == 401) {
						instance.logout();
					} else {
						deferred.reject(response);
					}
				});
			} else if(data.type == 'delete') {
				$http.delete($rootScope.setup.api + data.url).then(function(response) {
					deferred.resolve(response);
				},function(response) {
					if(response.status == 401) {
						instance.logout();
					} else {
						deferred.reject(response);
					}
				});
			}

			// # Return promise

			return deferred.promise;

		} catch(e) { console.error(e); }
	};

	// # Logout

	instance.logout = function() {

		// # Update data

		$rootScope.user = $storage.rm('user');
		$rootScope.token = $storage.rm('token'); $appService.remove_token();
		instance.remove_session();

		// # Redirect

		$location.path('/');

	};

	// # Return

	return instance;

});